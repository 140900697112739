import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './AppContext';
import { sendToAnalytics } from './LoggingUtilities';

ReactDOM.render(
  <React.StrictMode>
      <ContextProvider>
        <App />
      </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
