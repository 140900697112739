import { useContext, memo } from 'react';
import { AppContext } from '../AppContext';
import { WORKFLOW } from '../Constants';

const WorkflowDropdown = memo(({ workflow, setWorkflow }) => {
    const onChange = (e) => {
        setWorkflow(e.target.value);
    };

    return (
        <select value={workflow} onChange={onChange}>
            <option value={WORKFLOW.Receiver}>Receive photos</option>
            <option value={WORKFLOW.Sender}>Send photos</option>
        </select>
    )
});

export default function ConnectedWorkflowDropdown() {
    const { state, actions } = useContext(AppContext);
    const { workflow } = state;
    const { setWorkflow } = actions;

    return (
        <WorkflowDropdown 
            workflow={workflow}
            setWorkflow={setWorkflow}
        />
    );
}