import { PureComponent, createContext } from "react";
import { WORKFLOW, QUERY_PARAM_ID, PIQR_URL, QUERY_PARAM_ERROR, EVENT, SERVER_URL, QUERY_PARAM_WORKFLOW } from './Constants';
import { io } from "socket.io-client";
import { v1 as uuid } from "uuid";
import Peer from "simple-peer";
import SimplePeerFiles from './ThirdParty/simple-peer-files-main/src';
import { log } from './LoggingUtilities';

export const AppContext = createContext({});

const defaultState = {
    sessionId: null,
    workflow: WORKFLOW.Undefined,
    shouldShowParamError: false,
    receiverPics: [],
    hasMadeInitialConnection: false,
    isConnectionMade: false,
    isSessionActive: true,
    bytesSent: 0,
    isWaitingToSend: false,
    isSessionCreator: false
};

export class ContextProvider extends PureComponent {
    constructor(props) {
        super(props);

        this.state = defaultState;

        this.socket = null;
        this.peer = null;

        this.timePassed = 0;
        this.sessionTimeout = null;

        this.numFilesSent = 0;
        this.fileId = null;
        this.fileToSend = null;
    }

    componentDidMount() {
        this.initializeState();
    }

    initializeState = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const shouldShowError = urlParams.get(QUERY_PARAM_ERROR);

        if (shouldShowError) {
            this.showParamError();
        }

        let sessionId = urlParams.get(QUERY_PARAM_ID);
        const workflow = urlParams.get(QUERY_PARAM_WORKFLOW);

        if (sessionId && workflow) {
            // We don't set the workflow until the connection is made here to allow for a spinner
            this.joinSession(sessionId, workflow);
        } else {
            // We set this immediately so we can preload the landing page
            this.setState({ workflow: WORKFLOW.Receiver, isSessionCreator: true });

            sessionId = uuid();
            this.createSession(sessionId);
        }
    }

    createSession = (sessionId) => {
        this.socket = this.getSocket();

        log(EVENT.StartSession, { sessionId });
        this.socket.emit(EVENT.StartSession, sessionId);

        this.socket.on(EVENT.SessionStarted, () => {
            log(EVENT.SessionStarted, { sessionId });
            this.setState({ sessionId });
            // TODO set error message that can't connect if we don't reach here
        });

        this.socket.on(EVENT.SessionAlreadyExists, () => {
            log(EVENT.SessionAlreadyExists, { sessionId });
            this.endSession();
        });

        this.socket.on(EVENT.SessionDoesNotExist, () => {
            log(EVENT.SessionDoesNotExist, { sessionId });
            this.endSession();
        });

        this.socket.on(EVENT.SignalSent, ({ signal, callerId, iceServers }) => {
            log(EVENT.SignalSent);

            if (this.peer) {
                this.peer.destroy();
            }
            
            this.iceServers = iceServers;
            this.peer = this.joinConnection(signal, callerId);
        });

        this.socket.on(EVENT.DeletedSession, () => {
            log(EVENT.DeletedSession, { sessionId });
            this.endSession();
        });
    }

    joinSession = (sessionId, workflow) => {
        this.socket = this.getSocket();

        log(EVENT.JoinSession, { sessionId, workflow });
        this.socket.emit(EVENT.JoinSession, sessionId);

        this.socket.on(EVENT.SessionFull, () => {
            log(EVENT.SessionFull, { sessionId });

            // We might not want to end the session here, in case we try to restart the session twice
            // this.endSession();
        });

        this.socket.on(EVENT.SessionDoesNotExist, () => {
            log(EVENT.SessionDoesNotExist, { sessionId });
            this.endSession();
        });

        this.socket.on(EVENT.SessionJoined, ({ sessionCreator, iceServers }) => {
            log(EVENT.SessionJoined, { sessionId, sessionCreator });
            
            if (this.peer) {
                this.peer.destroy();
            }

            this.iceServers = iceServers;
            this.peer = this.initiateConnection(sessionCreator, this.socket.id, sessionId, workflow);
        });

        this.socket.on(EVENT.ReceivingReturnedSignal, payload => {
            log(EVENT.ReceivingReturnedSignal, payload);
            this.peer.signal(payload.signal);

            // TODO do we need this? 
            // this.setState({ hasMadeInitialConnection: true, isConnectionMade: true, isSessionActive: true });
        });

        this.socket.on(EVENT.DeletedSession, () => {
            log(EVENT.DeletedSession, { sessionId });
            this.endSession();
        });
    }

    getSocket = () => {
        return io(SERVER_URL, { withCredentials: true });
    }

    getPeerConfig = () => ({
        trickle: false,
        objectMode: true,
        config: { 
            iceServers: this.iceServers
        },
        reconnectTimer: 3000
    })

    initiateConnection = (userToSignal, callerId, sessionId, workflow) => {
        const peer = new Peer({
            initiator: true,
            ...this.getPeerConfig()
        });

        const stateToSet = { 
            sessionId, 
            workflow, 
            isSessionCreator: false 
        };

        peer.on('connect', () => this.onPeerConnect(stateToSet));

        // For initiator: true peers, this is called right away!
        peer.on('signal', signal => {
            this.socket.emit(EVENT.SendingSignal, { userToSignal, callerId, signal, sessionId });
        });

        peer.on('data', this.onPeerData)

        peer.on('close', () => {
            log(EVENT.PeerClose, { sessionId });
            this.onConnectionBroken();
        });
        peer.on('error', (err) => {
            log(EVENT.PeerError, { sessionId });
            this.onConnectionBroken();
        });

        return peer;
    }

    joinConnection = (incomingSignal, callerId) => {
        const peer = new Peer({
            initiator: false,
            ...this.getPeerConfig()
        });

        peer.on('connect', () => this.onPeerConnect({}));

        peer.on('signal', signal => {
            this.socket.emit(EVENT.ReturningSignal, { signal, callerId });
        });

        peer.on('data', this.onPeerData);

        peer.on('close', () => {
            log(EVENT.PeerClose, { sessionId: this.state.sessionId });
            this.onConnectionBroken();
        });
        peer.on('error', (err) => {
            log(EVENT.PeerError, { sessionId: this.state.sessionId });
            
            // We may not want to call this so it doesn't try to restart the session twice (and then get a SESSION_FULL and end the session)
            // this.onConnectionBroken();
        });

        peer.signal(incomingSignal);
        
        return peer;
    }

    onPeerConnect = (stateToSet) => {
        if (this.state.isWaitingToSend && this.fileId && this.fileToSend) {
            this.peer.send(`PREPARING_TO_SEND_${this.fileId}`);
        }

        if (!this.sessionTimeout) {
            const numMinutesUntilExpire = 15;

            setInterval(() => {
                this.timePassed += 1;
            }, 1000);

            this.sessionTimeout = setTimeout(() => {
                log(EVENT.SessionExpired, { sessionId: this.state.sessionId });
                this.endSession();
            }, numMinutesUntilExpire*60000);
        }

        this.setState({ 
            hasMadeInitialConnection: true, 
            isConnectionMade: true, 
            isSessionActive: true,
            ...stateToSet
        });
    }

    onPeerData = (data) => {
        if (data === 'READY_FOR_FILE') {
            const spf = new SimplePeerFiles();
            const config = this.getPeerConfig();

            spf.send(this.peer, this.fileId, this.fileToSend, config).then(transfer => {
                transfer.on('progress', (bytesSent) => {
                    this.setState({ bytesSent, isWaitingToSend: false });
                });

                transfer.on('done', () => {
                    this.fileId = null;
                    this.fileToSend = null;
                });
    
                transfer.start()
            });
        } else if (typeof data === 'string' && data.startsWith(EVENT.PreparingToSend)) {
            this.fileId = data.replace(`${EVENT.PreparingToSend}_`, '');

            const spf = new SimplePeerFiles();
            const config = this.getPeerConfig();

            spf.receive(this.peer, this.fileId, config).then(transfer => {
                transfer.on('progress', (bytesSent) => {
                    this.setState({ bytesSent });
                });
    
                transfer.on('done', file => {
                    log(EVENT.ReceivedFile, {
                        sessionId: this.state.sessionId,
                        fileSize: file.size,
                        fileId: this.fileId
                    });

                    const { receiverPics } = this.state;

                    // Because Chrome iOS can't save blobs made from URL.createObjectURL https://stackoverflow.com/questions/24485077/how-to-open-blob-url-on-chrome-ios
                    var reader = new FileReader();
                    reader.onload = () => {
                        this.setState({ isReceivingPic: false, receiverPics: [ { url: reader.result, id: receiverPics.length }, ...receiverPics] });
                    }
                    reader.readAsDataURL(file);        
                });
            });

            this.peer.send(EVENT.ReadyForFile);
        } else if (data === EVENT.EndSession) {
            this.endSession();
        }
    }

    // This can only be called via the close / error peer events. 
    // Don't wanna call this on visibility change since peer will still think 
    // it's "connected" until those events are called.
    onConnectionBroken = () => {
        const { isSessionActive, sessionId, isSessionCreator } = this.state;

        if (this.peer.connected) {
            this.peer.destroy();
        }

        this.setState({ isConnectionMade: false }, () => {
            if (this.socket && isSessionActive) {
                this.socket.emit(EVENT.RestartSession, sessionId, isSessionCreator);
            } else {
                this.endSession();
            }
        });
    }

    sendFile = (file) => {
        this.fileId = `FILE_${this.numFilesSent++}`;

        log(EVENT.SendFile, {
            sessionId: this.state.sessionId,
            fileSize: file.size,
            fileNum: this.numFilesSent,
            fileId: this.fileId
        });

        this.setState({ isWaitingToSend: true });
        this.fileToSend = file;

        if (this.peer && this.peer.connected) {
            this.peer.send(`${EVENT.PreparingToSend}_${this.fileId}`);
        }
    }

    endSession = () => {
        log(EVENT.EndSession, { 
            sessionId: this.state.sessionId, 
            numSecondsInSession: this.timePassed 
        });

        if (this.peer) {
            if (this.peer.connected) {
                this.peer.send(EVENT.EndSession);
            }

            this.peer.destroy();
        }

        if (this.state.workflow === WORKFLOW.Receiver) {
            if (this.state.receiverPics.length) {
                this.setState({ isSessionActive: false, isConnectionMade: false });
            } else {
                window.location.href = `${PIQR_URL}?${QUERY_PARAM_ERROR}=true`;
            }
        } else {
            this.setState({ isSessionActive: false, isConnectionMade: false });
        }
    }

    setWorkflow = (workflow) => {
        log(EVENT.SetWorkflow, { workflow });
        this.setState({ workflow });
    }

    showParamError = () => {
        // As of now this function is only called via a query parameter on refresh,
        // or from endSession as a Receiver. If this is ever called from the sender,
        // may want to reconsider this line to replace the query parameters in the url.
        window.history.replaceState(null, null, window.location.pathname);

        this.setState({ shouldShowParamError: true }, () => {
            setTimeout(() => {
                this.setState({ shouldShowParamError: false });
            }, 4000);
        });
    }

    render() {
        const contextValue = {
            state: this.state,
            actions: {
                sendPhoto: this.sendFile,
                endSession: this.endSession,
                setWorkflow: this.setWorkflow
            }
        };

        return (
            <AppContext.Provider value={contextValue}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}