import { EventEmitter } from 'ee-ts'
import { Duplex } from 'readable-stream'
import SimplePeer from 'simple-peer'
import * as read from 'filereader-stream'

import { ControlHeaders } from './Meta'

const CHUNK_SIZE = Math.pow(2, 16)

/**
 * Make a Uint8Array to send to peer
 * @param header Type of data. See Meta.ts
 * @param data
 */
function pMsg (header, data = null) {
  let resp
  if (data) {
    resp = new Uint8Array(1 + data.length)
    resp.set(data, 1)
  } else {
    resp = new Uint8Array(1)
  }
  resp[0] = header

  return resp
}

class SendStream extends Duplex {
  bytesSent = 0
  fileSize = 0 // file size
  paused = false
  cb

  constructor (fileSize, bytesSent = 0) {
    super()
    this.fileSize = fileSize
    this.bytesSent = bytesSent
  }

  _read () {
    if (this.cb) this.cb(null)
  }

  /**
   * File stream writes here
   * @param chunk
   * @param encoding
   * @param cb
   */
  _write (chunk, encoding, cb) {
    if (this.paused) return

    // Will return true if additional chunks of data may continue to be pushed
    const availableForMore = this.push(pMsg(ControlHeaders.FILE_CHUNK, chunk))

    this.bytesSent += chunk.byteLength
    const percentage = parseFloat((100 * (this.bytesSent / this.fileSize)).toFixed(3))
    this.emit('progress', percentage, this.bytesSent)

    if (availableForMore) {
      this.cb = null
      cb(null) // Signal that we're ready for more data
    } else {
      this.cb = cb
    }
  }
}

export default class PeerFileSend extends EventEmitter {
  paused = false;
  cancelled = false;
  receiverPaused = false
  peer;
  file;
  ss;

  // Bytes to start sending from
  offset = 0;

  /**
   * @param peer   Peer to send
   * @param file   File to send
   * @param offset Bytes to start sending from, useful for file resume
   */
  constructor (peer, file, offset = 0) {
    super()

    this.peer = peer
    this.file = file
    this.offset = offset
  }

  /**
   * Send a message to receiver
   * @param header Type of message
   * @param data   Message
   */
  sendPeer (header, data = null) {
    if (!this.peer.connected) return
    this.peer.send(pMsg(header, data))
  }

  // Info about file is sent first
  sendFileStartData () {
    const meta = {
      fileName: this.file.name,
      fileSize: this.file.size,
      fileType: this.file.type
    }
    const metaString = JSON.stringify(meta)
    const metaByteArray = new TextEncoder().encode(metaString)

    this.sendPeer(ControlHeaders.FILE_START, metaByteArray)
  }

  setPeer (peer) {
    this.peer = peer
  }

  // Start sending file to receiver
  _resume () {
    if (this.receiverPaused) return

    if (this.offset === 0) {
      // Start
      this.sendFileStartData()
      this.emit('progress', 0.0, 0)
    }

    // Chunk sending
    const stream = read(this.file, {
      offset: this.offset,
      chunkSize: CHUNK_SIZE
    })

    this.ss = new SendStream(this.file.size, this.offset)
    this.ss.on('progress', (percentage, bytes) => {
      this.emit('progress', percentage, bytes)
    })

    stream.pipe(this.ss).pipe(this.peer)
  }

  start () {
    // Listen for cancel requests
    this.peer.on('data', (data) => {
      if (data[0] === ControlHeaders.FILE_END) {
        this.emit('progress', 100.0, this.file.size)
        this.emit('done')
      } else if (data[0] === ControlHeaders.TRANSFER_PAUSE) {
        this._pause()

        this.receiverPaused = true
        this.emit('paused')
      } else if (data[0] === ControlHeaders.TRANSFER_RESUME) {
        this.receiverPaused = false

        if (!this.paused) {
          this._resume()
          this.emit('resumed')
        }
      } else if (data[0] === ControlHeaders.TRANSFER_CANCEL) {
        this.cancelled = true
        this.peer.destroy()

        this.emit('cancelled')
      }
    })

    this._resume()
  }

  // Pause transfer and store the bytes sent till now for resuming later
  _pause () {
    this.ss.paused = true
    this.offset = this.ss.bytesSent
  }

  // Stop sending data now & future sending
  pause () {
    this._pause()
    this.paused = true

    this.sendPeer(ControlHeaders.TRANSFER_PAUSE)
    this.emit('pause')
  }

  // Allow data to be sent & start sending data
  resume () {
    this.paused = false
    this._resume()
    this.emit('resume')
  }

  cancel () {
    this.cancelled = true
    this.ss.destroy()
    this.sendPeer(ControlHeaders.TRANSFER_CANCEL)
    this.peer.destroy()
    this.emit('cancel')
  }
}
