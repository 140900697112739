import { useRef, useContext, memo } from "react";
import './Camera.scss';
import logo from '../../Resources/piqr-logo-sketch.svg';
import checkmark from '../../Resources/Icons/check-circle.svg';
import { AppContext } from "../../AppContext";
import EndSessionButton from "../../CommonComponents/EndSessionButton/EndSessionButton";
import ProgressCircle from "../../CommonComponents/ProgressCircle";
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';

const Camera = memo(({ 
    sessionId, 
    sendPhoto, 
    bytesSent, 
    isWaitingToSend, 
    hasMadeInitialConnection 
}) => {
    const cameraInputRef = useRef();

    const onCapturePhoto = (e) => {
        const photoFile = e.target.files[0];

        sendPhoto(photoFile);
    }

    const onClickOpen = () => {
        cameraInputRef.current.click();
    }

    const isSent = bytesSent === 100;
    const isSending = bytesSent > 0 && bytesSent < 100;

    let squareSection;
    if (!hasMadeInitialConnection) {
        squareSection = (
            <div className='loading-area'>
                <LoadingSpinner />
                <div className='button-label'>Connecting...</div>
            </div>
        );
    } else if (isWaitingToSend) {
        squareSection = (
            <div className='loading-area'>
                <LoadingSpinner />
                <div className='button-label'>Waiting to send...</div>
            </div>
        );
    } else if (isSent) {
        squareSection = (
            <div className='sent-area'>
                <img src={checkmark} alt={''}/>
                <div className='button-label'>Sent</div>
            </div>
        );
    } else if (isSending) {
        squareSection = (
            <div className='loading-area'>
                <ProgressCircle />
                <div className='button-label'>Sending...</div>
            </div>
        );
    } else {
        squareSection = (
            <button
                className='open-camera-button'
                onClick={onClickOpen}>
                <img src={logo} alt={''} />
                <div className='button-label'>Open camera</div>
            </button>
        );
    }

    return (
        <div className='camera-wrapper'>
            {squareSection}
            {isSent && 
                <div className='button-section'>
                    <EndSessionButton />
                    <button onClick={onClickOpen}>Take another</button>
                </div>
            }
            <input
                className='camera-input'
                ref={cameraInputRef} 
                type="file" 
                accept="image/*" 
                capture="camera" 
                onChange={onCapturePhoto}
            />
        </div>
    );
});

export default function ConnectedCamera() {
    const { state, actions } = useContext(AppContext);
    const { sessionId, bytesSent, isWaitingToSend, hasMadeInitialConnection } = state;
    const { sendPhoto } = actions;

    return (
        <Camera 
            sessionId={sessionId} 
            bytesSent={bytesSent} 
            sendPhoto={sendPhoto} 
            isWaitingToSend={isWaitingToSend}
            hasMadeInitialConnection={hasMadeInitialConnection} />
    );
} 