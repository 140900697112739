export const QUERY_PARAM_ID = 'sessionId';
export const QUERY_PARAM_ERROR = 'showError';
export const QUERY_PARAM_WORKFLOW = 'workflow';

export const LOCAL_IP = '10.0.0.186';

export const SERVER_URL = process.env.NODE_ENV === 'development'
    ? `http://${LOCAL_IP}:5000`
    : 'https://piqr.herokuapp.com/';

export const PIQR_URL = process.env.NODE_ENV === 'development'
    ? `http://${LOCAL_IP}:3000`
    : 'https://piqr.me';

export const THEME_COLOR_MAIN = '#17d2c3';
export const THEME_COLOR_SECONDARY = '#ffffff';

export const WORKFLOW = {
    Undefined: 'UNDEFINED',
    Sender: 'SENDER',
    Receiver: 'RECEIVER'
};

export const EVENT = {
    StartSession: 'START_SESSION',
    SessionStarted: 'SESSION_STARTED',
    SessionAlreadyExists: 'SESSION_ALREADY_EXISTS',
    SessionDoesNotExist: 'SESSION_DOES_NOT_EXIST',
    SignalSent: 'SIGNAL_SENT',
    DeletedSession: 'DELETED_SESSION',
    JoinSession: 'JOIN_SESSION',
    SessionFull: 'SESSION_FULL',
    SessionJoined: 'SESSION_JOINED',
    ReceivingReturnedSignal: 'RECEIVING_RETURNED_SIGNAL',
    SendingSignal: 'SENDING_SIGNAL',
    ReturningSignal: 'RETURNING_SIGNAL',
    RestartSession: 'RESTART_SESSION',
    SessionExpired: 'SESSION_EXPIRED',

    PeerClose: 'PEER_CLOSE',
    PeerError: 'PEER_ERROR',

    PreparingToSend: 'PREPARING_TO_SEND',
    ReadyForFile: 'READY_FOR_FILE',
    EndSession: 'END_SESSION',

    SendFile: 'SEND_FILE',
    ReceivedFile: 'RECEIVED_FILE',

    NewSession: 'NEW_SESSION',
    SetWorkflow: 'SET_WORKFLOW'
};