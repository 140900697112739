import './LandingPage.scss';
import QrCode from './QrCode/QrCode';
import WorkflowDropdown from './WorkflowDropdown';

export default function LandingPage() {
    const onClickHowItWorks = (e) => {
        e.target.scrollIntoView(true, { behavior: 'smooth' });
    }

    return (
        <div className='landing-page'>
            <div className='qr-section'>
                <div className='landing-header'>
                    <h1 className='title'>piqr.me</h1>
                    <div className='subtitle'>Photo sharing made safe</div>
                </div>
                <QrCode />
                <div>
                    <span className='dropdown-label'>I want to:</span>
                    <WorkflowDropdown />
                </div>
            </div>
            <div className='info'>
                <div className='how-it-works'>
                    <h2 className='section-header' onClick={onClickHowItWorks}>How it works</h2>
                    <div><span className='step-number'>1</span>Choose if you'd like to receive or send photos.</div>
                    <div><span className='step-number'>2</span>Point another smartphone camera at the QR code above.</div>
                    <div><span className='step-number'>3</span>Open the secure link to instantly receive and send photos!</div>
                </div>
                <div className='blurb'>Photos are never saved on the phone taking them, or anywhere else for that matter.</div>
            </div>
        </div>
    );
};