import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyBCi7pBHauxI2hub4hjN1IF5nQQZCB-XpU",
    authDomain: "piqr-393aa.firebaseapp.com",
    databaseURL: "https://piqr-393aa.firebaseio.com",
    projectId: "piqr-393aa",
    storageBucket: "piqr-393aa.appspot.com",
    messagingSenderId: "1024694204504",
    appId: "1:1024694204504:web:02d5998ab487fea470322d",
    measurementId: "G-JN025C44M9"
};

firebase.initializeApp(config);

export const analytics = firebase.analytics();

// export default firebase;