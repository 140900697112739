import Loader from "react-loader-spinner";
import { THEME_COLOR_MAIN } from "../Constants";

export default function LoadingSpinner() {
    return (
        <Loader
            type="Circles"
            color={THEME_COLOR_MAIN}
            height={75}
            width={75}
        />
    )
}