import Camera from './Camera/Camera';
import { AppContext } from '../AppContext';
import EndedSessionWorkflow from '../EndedSessionWorkflow/EndedSessionWorkflow';
import { useContext } from 'react';

function SenderWorkflow({ isSessionActive }) {
    return (
        <div className='sender-workflow'>
            {isSessionActive
                ? <Camera />
                : <EndedSessionWorkflow />
            }
        </div>
    )
}

export default function ConnectedSenderWorkflow() {
    const { state } = useContext(AppContext);
    const { isSessionActive } = state;

    return (
        <SenderWorkflow isSessionActive={isSessionActive} />
    );
} 