import { useContext, memo } from 'react';
import './Header.scss';
import logo from '../Resources/piqr-logo-sketch.svg';
import { AppContext } from '../AppContext';
import LoadingSpinner from '../CommonComponents/LoadingSpinner';
import checkmark from '../Resources/Icons/check-circle.svg';
import error from '../Resources/Icons/error-icon.svg';

const Header = memo(({ isSessionActive, isConnecting, hasMadeInitialConnection }) => {
    let icon;
    if (isConnecting) {
        icon = <LoadingSpinner />;
    } else if (isSessionActive) {
        icon = <img src={checkmark} alt={''} />;
    } else {
        icon = <img src={error} alt={''} />;
    }


    return (
        <div className='header'>
            <div className='logo-section'>
                <img className='logo' src={logo} alt={''} />
                <h1 className='logo-url'>piqr.me</h1>
            </div>
            {hasMadeInitialConnection &&
                <div className='connection-icon'>
                    {icon}
                </div>
            }
        </div>
    );
});

export default function ConnectedHeader() {
    const { state } = useContext(AppContext);
    const { isConnectionMade, isSessionActive, hasMadeInitialConnection } = state;
    
    const isConnecting = isSessionActive && !isConnectionMade;

    return (
        <Header 
            hasMadeInitialConnection={hasMadeInitialConnection} 
            isConnecting={isConnecting} 
            isSessionActive={isSessionActive} />
    );
} 