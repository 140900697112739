import { useState, useContext, memo, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { AppContext } from '../../AppContext';
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';
import { THEME_COLOR_SECONDARY, PIQR_URL, QUERY_PARAM_ID, WORKFLOW, QUERY_PARAM_WORKFLOW } from '../../Constants';
import logo from '../../Resources/piqr-logo-sketch.svg';
import './QrCode.scss';
import { useRef } from 'react';

const QrCode = memo(({ sessionId, workflow }) => {
    const errorTimeoutRef = useRef(null);
    const [shouldShowError, setShouldShowError] = useState(false);

    useEffect(() => {
        if (!sessionId && !errorTimeoutRef.current) {
            errorTimeoutRef.current = setTimeout(() => {
                setShouldShowError(true);
            }, 10000);
        } else {
            errorTimeoutRef.current && clearTimeout(errorTimeoutRef.current);
        }
    }, [sessionId]);

    const onClickRefresh = () => {
        document.location.reload();
    }

    const otherWorkflow = workflow === WORKFLOW.Receiver 
        ? WORKFLOW.Sender 
        : WORKFLOW.Receiver;

    const value = `${PIQR_URL}?${QUERY_PARAM_ID}=${sessionId}&${QUERY_PARAM_WORKFLOW}=${otherWorkflow}`;

    const size = 250;

    if (!sessionId) {
        return (
            <div className='qr-spinner-wrapper' style={{width: size + 40, height: size + 40}}>
                <LoadingSpinner />
                {shouldShowError && 
                    <div className='error-section'>
                        This is taking longer than it should...
                        <button onClick={onClickRefresh}>Refresh</button>
                    </div>
                }
            </div>
        );
    }

    console.log('value', value);

    return (
        <div className='qr-code'>
            <QRCode 
                value={value}
                size={size}
                logoImage={logo}
                logoWidth={size*0.25}
                qrStyle={'dots'}
                fgColor={'black'}
                bgColor={THEME_COLOR_SECONDARY}
            />
        </div>
    );
});

export default function ConnectedQrCode() {
    const { state } = useContext(AppContext);
    const { sessionId, workflow } = state;
                
    return (
        <QrCode 
            sessionId={sessionId} 
            workflow={workflow}
        />
    );
} 