import { useContext, memo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AppContext } from '../AppContext';
import { THEME_COLOR_MAIN, THEME_COLOR_SECONDARY } from '../Constants';

const ProgressCircle = memo(({ bytesSent }) => {
    const styles = buildStyles({
        strokeLinecap: 'butt',
        textColor: THEME_COLOR_MAIN,
        pathColor: THEME_COLOR_MAIN,
        trailColor: THEME_COLOR_SECONDARY,
        pathTransitionDuration: 0
    });
    
    return (
        <CircularProgressbar 
            value={bytesSent}
            text={`${Math.round(bytesSent)}%`}
            strokeWidth={5}
            styles={styles}
        />
    );
});

export default function ConnectedProgressCircle() {
    const { state } = useContext(AppContext);
    const { bytesSent } = state;

    return (
        <ProgressCircle bytesSent={bytesSent} />
    );
}