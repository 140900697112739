import { memo, useContext } from 'react';
import './Gallery.scss';
import EndSessionButton from '../CommonComponents/EndSessionButton/EndSessionButton';
import { AppContext } from '../AppContext';
import { useRef } from 'react';
import checkmark from '../Resources/Icons/check-circle.svg';
import polaroid from '../Resources/Icons/polaroid.svg';
import qrIcon from '../Resources/Icons/qr-icon.svg';
import { useState } from 'react';
import Modal from '../CommonComponents/Modal/Modal';
import QrCode from '../LandingPage/QrCode/QrCode';
import ProgressCircle from '../CommonComponents/ProgressCircle';
import { PIQR_URL, EVENT } from '../Constants';
import { log } from '../LoggingUtilities';

const Gallery = memo(({ sessionId, receiverPics, isSessionActive, isReceivingPic }) => {
    const galleryPicsRef = useRef();
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);

    const scrollToTop = () => {
        galleryPicsRef && galleryPicsRef.current && galleryPicsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

    // TODO remove this icon?
    const onClickQrIcon = () => {
        if (isSessionActive) {
            setIsQrModalOpen(true);
        }
    }

    const onDismissModal = () => {
        setIsQrModalOpen(false);
    }

    const onClickNewSession = () => {
        log(EVENT.NewSession, { sessionId });
        window.location.href = PIQR_URL;
    }

    const hasReceivedPics = !!receiverPics.length;

    return (
        <div className='gallery'>
            <div className='gallery-header' />
            {hasReceivedPics && 
                <div className='header-content'>
                    <span className='photo-count'>
                        <img className='polaroid' src={polaroid} alt={''} />
                        {receiverPics.length}
                    </span>
                    <img className={`qr-icon ${isSessionActive ? '' : 'not-active'}`} onClick={onClickQrIcon} src={qrIcon} alt={''} />
                    {isQrModalOpen &&
                        <Modal onDismiss={onDismissModal}>
                            <QrCode />
                        </Modal>
                    }
                </div>
            }
            <div className='gallery-pics' ref={galleryPicsRef}>
                {!hasReceivedPics && !isReceivingPic &&
                    <div className='connection-made-section'>
                        <img src={checkmark} alt={''}/>
                        <label>Connection established!</label>
                        <label>Your photos will show up here.</label>
                    </div>
                }
                {isReceivingPic &&
                    <div className='loading-img'>
                        <ProgressCircle />
                    </div>
                }
                {receiverPics.map(p => {
                    return (
                        <div className='img-container' key={p.id}>
                            <img className='preview-img' src={p.url} alt={'piqr.me'} onLoad={scrollToTop} />
                        </div>
                    );
                })}
            </div>
            <div className='gallery-footer' />
            {hasReceivedPics && isSessionActive &&
                <div className={'footer-message'}>
                    Press and hold a photo to download
                </div>
            }
            {!isSessionActive &&
                <div className={'footer-message not-active'}>
                    This session is no longer active
                </div>
            }
            <div className='button-section'>
                {isSessionActive
                    ? <EndSessionButton />
                    : <button onClick={onClickNewSession}>New session</button>
                }
            </div>
        </div>
    );
});

export default function ConnectedGallery() {
    const { state } = useContext(AppContext);
    const { isSessionActive, receiverPics, bytesSent, sessionId } = state;

    const isReceivingPic = bytesSent > 0 && bytesSent < 100;

    return (
        <Gallery 
            sessionId={sessionId}
            isSessionActive={isSessionActive}
            isReceivingPic={isReceivingPic}
            receiverPics={receiverPics} />
    );
} 