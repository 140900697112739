import { PIQR_URL } from '../Constants';
import './EndedSessionWorkflow.scss';

export default function EndedSessionWorkflow() {
    const onClickNewSession = () => {
        window.location.href = PIQR_URL;
    };

    return (
        <div className='ended-session-workflow'>
            Session ended
            <button 
                className='new-session-button' 
                onClick={onClickNewSession}>
                    New session
            </button>
        </div>
    );
}