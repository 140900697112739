import { createPortal } from 'react-dom';
import './Modal.scss';

export default function Modal({ children, onDismiss }) {
    return createPortal(
        <div className='overlay' onClick={onDismiss}>
            {children}
        </div>,
        document.getElementById('piqr-app')
    );
}