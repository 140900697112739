import { analytics } from './Firebase/Firebase';

const logConsole = (...args) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log(...args);
    }
}

const logAnalytics = analytics.logEvent;

export const log = process.env.NODE_ENV === 'production'
    ? logAnalytics
    : logConsole;
// export const log = (...args) => console.log(...args);

// For use by reportWebVitals in index.js
export function sendToAnalytics({ id, name, value }) {
    analytics.logEvent('WEB_VITALS', {
        id,
        name,
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    });
}