import { AppContext } from "../../AppContext";
import { useState, useContext, memo } from 'react';
import { createPortal } from 'react-dom';
import Modal from '../Modal/Modal';
import './EndSessionButton.scss';

const EndSessionButton = memo(({ endSession }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClickYes = () => {
        setIsModalOpen(false);
        endSession();
    };

    const onClickNo = () => {
        setIsModalOpen(false);
    }

    const onClickEndSession = () => {
        setIsModalOpen(true);
    }

    return (
        <>
            {isModalOpen && createPortal(
                <Modal onDismiss={onClickNo}>
                    <div className='are-you-sure-modal'>
                        <h1>Are you sure?</h1>
                        <div className='button-section'>
                            <button onClick={onClickNo}>No</button>
                            <button onClick={onClickYes}>Yes</button>
                        </div>
                    </div>
                </Modal>,
                document.getElementById('piqr-app')
            )}
            <button className='end-session-button' onClick={onClickEndSession}>
                End session
            </button>
        </>
    );
});

export default function ConnectedEndSessionButton() {
    const { actions } = useContext(AppContext);
    const { endSession } = actions;

    return (
        <EndSessionButton endSession={endSession} />
    );
} 