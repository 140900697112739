import './App.scss';
import { WORKFLOW } from './Constants';
import SenderWorkflow from './SenderWorkflow/SenderWorkflow';
import Header from './Header/Header';
import { PureComponent, createRef, useContext } from 'react';
import ErrorToast from './CommonComponents/ErrorToast/ErrorToast';
import { AppContext } from './AppContext';
import Gallery from './Gallery/Gallery';
import LandingPage from './LandingPage/LandingPage';
import LoadingSpinner from './CommonComponents/LoadingSpinner';

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.appContainerRef = createRef();

        this.state = {
            isMounted: false
        };
    }

    componentDidMount() {
        this.setAppContainerHeight();
    }

    setAppContainerHeight = () => {
        this.appContainerRef.current.style.height = `${window.innerHeight}px`;
        this.setState({ isMounted: true });
    }
    
    render() {
        const { workflow, shouldShowParamError, hasMadeInitialConnection } = this.props;
        const { isMounted } = this.state;

        let mainContent;

        if (workflow === WORKFLOW.Undefined) {
            mainContent = (
                <div className='spinner-wrapper'>
                    <LoadingSpinner />
                </div>
            );
        } else if (!hasMadeInitialConnection) {
            mainContent = <LandingPage />;
        } else {
            mainContent = workflow === WORKFLOW.Receiver
                ? <Gallery />
                : <SenderWorkflow />;
        }

        return (
            <div className="piqr-app" id="piqr-app" ref={this.appContainerRef}>
                <Header />
                {isMounted &&
                    <div className='content'>
                        {shouldShowParamError && 
                            <ErrorToast message={'Oops! That session is no longer available.'} />
                        }
                        {mainContent}
                    </div>
                }
            </div>
        );
    }
}

export default function ConnectedApp() {
    const { state } = useContext(AppContext);
    const { workflow, shouldShowParamError, hasMadeInitialConnection } = state;

    return (
        <App 
            workflow={workflow}
            shouldShowParamError={shouldShowParamError}
            hasMadeInitialConnection={hasMadeInitialConnection} />
    );
} 